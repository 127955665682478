.hidden- {
    @include break-above(lg) {
        &desktop {
            display: none;
        }
    }

    @include break-between(xs, lg) {
        &tablet {
            display: none;
        }
    }

    @include break-below(xs) {
        &mobile {
            display: none;
        }
    }
}

.is-full-height {
    height: calc(100vh - var(--header-height) - var(--admin-bar));
}