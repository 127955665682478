// More information: https://glennmccomb.com/articles/useful-sass-scss-media-query-mixins-for-bootstrap/

// @include break-above(sm) {}
@mixin break-above($breakpoint) {
	// If the breakpoint exists in the map.
	@if map-has-key($breakpoints, $breakpoint) {
		// Get the breakpoint value.
		$breakpoint-value: map-get($breakpoints, $breakpoint);

		// Write the media query.
		@media (min-width: $breakpoint-value) {
			@content;
		}

		// If the breakpoint doesn't exist in the map.
	} @else {
		// Log a warning.
		@warn 'Invalid breakpoint: #{$breakpoint}.';
	}
}

// @include break-below(sm) {}
@mixin break-below($breakpoint) {
	// If the breakpoint exists in the map.
	@if map-has-key($breakpoints, $breakpoint) {
		// Get the breakpoint value.
		$breakpoint-value: map-get($breakpoints, $breakpoint);

		// Write the media query.
		@media (max-width: ($breakpoint-value - 1)) {
			@content;
		}

		// If the breakpoint doesn't exist in the map.
	} @else {
		// Log a warning.
		@warn 'Invalid breakpoint: #{$breakpoint}.';
	}
}

// @include break-between(sm, md) {}
@mixin break-between($lower, $upper) {
	// If both the lower and upper breakpoints exist in the map.
	@if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper)
	{
		// Get the lower and upper breakpoints.
		$lower-breakpoint: map-get($breakpoints, $lower);
		$upper-breakpoint: map-get($breakpoints, $upper);

		// Write the media query.
		@media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
			@content;
		}

		// If one or both of the breakpoints don't exist.
	} @else {
		// If lower breakpoint is invalid.
		@if (map-has-key($breakpoints, $lower) == false) {
			// Log a warning.
			@warn 'Your lower breakpoint was invalid: #{$lower}.';
		}

		// If upper breakpoint is invalid.
		@if (map-has-key($breakpoints, $upper) == false) {
			// Log a warning.
			@warn 'Your upper breakpoint was invalid: #{$upper}.';
		}
	}
}


// Border
@mixin magic-border($width, $color, $duration, $direction){
	position:relative;
	  &:before{
		content:'';
		position:absolute;
		width:calc(100% + #{$width * 2});
		height:calc(100% + #{$width * 2});
		top:calc(#{$width}/-1);
		left:calc(#{$width}/-1);
		background:linear-gradient(to right, $color 0%, $color 100%), linear-gradient(to top, $color 50%, transparent 50%), linear-gradient(to top, $color 50%, transparent 50%), linear-gradient(to right, $color 0%, $color 100%), linear-gradient(to left, $color 0%, $color 100%);
		background-size:100% $width, $width 200%, $width 200%, 0% $width, 0% $width;
		background-position:50% 100%, 0% 0%, 100% 0%, 100% 0%, 0% 0%;
		background-repeat:no-repeat, no-repeat;
		transition:transform $duration ease-in-out, background-position $duration ease-in-out, background-size $duration ease-in-out;
		transform:scaleX(0) rotate(180deg * $direction);
		transition-delay:$duration*2, $duration, 0s;
	  }
	  &:hover{
		&:before{
		  background-size:200% $width, $width 400%, $width 400%, 55% $width, 55% $width;
		  background-position:50% 100%, 0% 100%, 100% 100%, 100% 0%, 0% 0%;
		  transform:scaleX(1) rotate(180deg * $direction);
		  transition-delay:0s, $duration, $duration*2;
		}
	  }
  }
  
  @mixin magic-border-mask($width, $duration, $background, $direction){
	position:relative;
	  &:before{
		content:'';
		position:absolute;
		width:calc(100% + #{$width * 2});
		height:calc(100% + #{$width * 2});
		top:calc(#{$width}/-1);
		left:calc(#{$width}/-1);
		mask:linear-gradient(to right, #000 0%, #000 100%), linear-gradient(to top, #000 50%, transparent 50%), linear-gradient(to top, #000 50%, transparent 50%), linear-gradient(to right, #000 0%, #000 100%), linear-gradient(to left, #000 0%, #000 100%);
		mask-size:100% $width, $width 200%, $width 200%, 0% $width, 0% $width;
		mask-position:50% 100%, 0% 0%, 100% 0%, 100% 0%, 0% 0%;
		mask-repeat:no-repeat, no-repeat;
		transition:transform $duration ease-in-out, mask-position $duration ease-in-out, mask-size $duration ease-in-out;
		transform:scaleX(0) rotate(180deg * $direction);
		transition-delay:$duration*2, $duration, 0s;
		background:$background;
	  }
	  &:hover{
		&:before{
		  mask-size:200% $width, $width 400%, $width 400%, 55% $width, 55% $width;
		  mask-position:50% 100%, 0% 100%, 100% 100%, 100% 0%, 0% 0%;
		  transform:scaleX(1) rotate(180deg * $direction);
		  transition-delay:0s, $duration, $duration*2;
		}
	  }
  }