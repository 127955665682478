:root {
  --swiper-theme-color: var(--paletteColor1);
}

// Fix margin between sections
body:not(.single) {
  .site-main {
    .entry-content > * {
      margin-bottom: 0;
    }
  }
}

// Fix min-height for covers
.wp-block-cover,
.wp-block-cover-image {
  min-height: 240px;
}

#main {
  .wp-block-separator {
    margin-top: var(--content-spacing);
    margin-bottom: var(--content-spacing);

    &:not(.is-style-dots):not(.is-style-wide) {
      --block-max-width: 12%;
    }
  }
}

.magic-border {
  @include magic-border(1px, var(--paletteColor2), 250ms, 0);
}